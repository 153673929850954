import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay, Subject, tap } from 'rxjs'
import { LoginDto } from '../models/login-dto';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Router } from '@angular/router';
import { RegistrationDto } from '../models/registration-dto';

export enum ClaimKey {
    name = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    email = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    expiration = "exp"
}

@Injectable({ providedIn: 'platform' })
export class AuthService {
    private _isAuthenticated = new Subject<boolean>();
    public isAuthenticated$ = this._isAuthenticated.asObservable();
    private _decodedJWT = {};


    constructor(
        private router: Router,
        private http: HttpClient) {
    }

    login(login: LoginDto) {
        return this.http.post<any>('/api/auth/login', login)
            .pipe(
                tap(x => {
                    localStorage.setItem('id_token', x.token);

                    this._isAuthenticated.next(true);
                    this.router.navigate(['/'])
                }));
    }

    forgotPassword(email: string) {
        email = email.trim();

        const options =
        {
            params: new HttpParams().set('email', email)
        };
        return this.http.get<any>('/api/auth/forgotPassword', options);
    }

    register(input: RegistrationDto) {
        return this.http.post<any>('api/auth/register', input);

    }

    resetPassword(input: LoginDto) {
        return this.http.post<any>('/api/auth/resetPassword', input);

    }

    logout(reroute?: any[]) {
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        this._isAuthenticated.next(false);
        this._decodedJWT = {};

        if (reroute) {
            this.router.navigate(reroute);
        } else {
            this.router.navigate(['/']);
        }
    }

    public isLoggedIn() {
        this.setClaims();
        const expiry = this.getExp() * 1000;
        const isValid = new Date().getTime() <= expiry;
        this._isAuthenticated.next(isValid);
        return isValid;
    }

    confirmEmail(userId: string, code: string) {
        userId = userId.trim();
        code = code.trim();

        const options =
        {
            params: new HttpParams().set('userId', userId).set('code', code)

        };
        return this.http.get<any>('/api/auth/ConfirmEmail', options);
    }

    setClaims() {
        let token = localStorage.getItem('id_token');
        if (token) {
            this._decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
            this.getExp();
        }
    }

    getName() {
        return this._decodedJWT[ClaimKey.name];
    }
    getRoles() {
        return this._decodedJWT[ClaimKey.role];
    }
    getExp() {
        const exp = this._decodedJWT[ClaimKey.expiration];
        console.log(`expires at: ${new Date(exp * 1000)}`)
        return exp;
    }
    isAdmin() {
        return this.getRoles()?.includes("Admin");
    }
}
