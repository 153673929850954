import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators'
import { SpinnerOverlayService } from '../services/spinner-overlay.service';
import Swal from 'sweetalert2';
import { SwalConfs } from '../sweetalert-configurations';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(public loaderService: SpinnerOverlayService,) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const subscription = this.loaderService.spinner$.subscribe();

    this.requests.push(request);
    if (this.requests.length == 1) {
      this.loaderService.show();
    }
    return new Observable(observer => {
      const subscription = next.handle(request)
        .subscribe({
          next: event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              this.loaderService.hide();
              observer.next(event.clone({ body: event.body.data }));
            } else {
              observer.next(event);
            }
          },
          error: err => {
            this.handleErrorToast(err);
            this.removeRequest(request);
            this.loaderService.hide();
          },
          complete: () => {
            this.removeRequest(request);
            observer.complete();
            this.loaderService.hide();
          }
        });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
        this.loaderService.hide();

      };
    });
  }

  private handleErrorToast(err: any) {
    if (!err.error) {
      switch (err.status) {
        case 404:
          Swal.fire(SwalConfs.getErrorToast('Error', 'Not Found'));
          break;
        case 401:
          Swal.fire(SwalConfs.getErrorToast('Error', 'Unauthorized'));
          break;
        default:
          Swal.fire(SwalConfs.getErrorToast('Error', err.message));

      }
    } else {
      Swal.fire(SwalConfs.getErrorToast('Error', err.error.Error));
    }
  }
}
