import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faCalendarDays,
    faCalendarPlus,
    faCity,
    faHouse,
    faHouseMedical,
} from '@fortawesome/free-solid-svg-icons';
import { NavBarItem } from '@models/nav-bar-item';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';

@Component({
    standalone: true,
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
    imports: [FontAwesomeModule, RouterModule, CommonModule, NgbNavModule, NgbDropdownModule],
})
export class NavMenuComponent {
    isExpanded = false;
    isAuthenticated = false;
    navItems: NavBarItem[] = [];
    adminNavItems: NavBarItem[] = [];

    constructor(public auth: AuthService) {
        this.navItems = [
            { name: 'Events', routerLink: '/All' },
            { name: 'Venues', routerLink: '/venues/All' },
            { name: 'About', routerLink: '/about' },
        ];
    }

    ngOnInit() {
        this.auth.isAuthenticated$.subscribe(
          x => {
            this.isAuthenticated = x;
            if (this.isAuthenticated) {
              this.setAdminNavItems();
            }
          }
        )
        this.isAuthenticated = this.auth.isLoggedIn();
        if (this.isAuthenticated) {
          this.setAdminNavItems();
        }
    }

    setAdminNavItems() {
        this.adminNavItems = [
            {
                routerLink: '/admin/events',
                name: 'Event List',
                icon: faCalendarDays,
            },
            { routerLink: '/admin/venues', name: 'Venue List', icon: faHouse },
            { routerLink: '/admin/cities', name: 'City List', icon: faCity },
        ];
    }

    ngOnDestroy(): void {}

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
