import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'Humboldt Concerts and More';
  subtitle = 'Events from all your favorite venues in one place.';
  bgUrl = '../../../assets/splash-proto4.jpg'

  constructor() { }

  ngOnInit(): void {
  }

}
