<header>
    <nav class="navbar navbar-expand-md navbar-toggleable-md bg-primary">
        <div class="container">
            <a class="navbar-brand" [routerLink]="['/']">TheFortress</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
                aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse d-md-inline-flex justify-content-between"
                [ngClass]="{ show: isExpanded }">
                <ul class="navbar-nav">
                    <li *ngFor="let item of navItems" class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link" [routerLink]="[item.routerLink]">{{item.name}} </a>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li *ngIf="isAuthenticated && auth.isAdmin()" class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [routerLink]="['admin']">Admin</a>
                    </li>
                    <ng-container *ngIf="isAuthenticated || (auth.isAuthenticated$ | async); else loggedOut">
                        <li class="nav-item">
                            <a class="nav-link text-light" [routerLink]="['/account/info']">Hello,
                                {{auth.getName()}}</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link text-light" (click)="auth.logout()">Logout</a>
                        </li>
                    </ng-container>

                    <ng-template #loggedOut>
                        <li class="nav-item">
                            <a class="nav-link text-light" routerLink="/auth/login">Login</a>
                        </li>
                        <li *ngIf="false" class="nav-item">
                            <a class="nav-link text-light" routerLink="/auth/register">Register</a>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </nav>
</header>